
import Component from "vue-class-component";
import { IFormOption, INPUT_TYPES } from "~/components/form/FormTypes";
import { BaseForm } from "~/core/BaseForm";
import SwitchToggle from "@/components/base/SwitchToggle.vue";
import { Prop, Provide } from "vue-property-decorator";
import { NewRequester } from "~/core/Requester";
import { Core } from "~/core/Core";
import { NoCache } from "~/core/Decorators";
import RuleHelper from "~/utils/RuleHelper";
import { ObjectLoader } from "~/loader/object";
import { ILoaderOption } from "~/loader/types";
import SweetAlertOptions from "vue-sweetalert2";
import { UnexpectedError, SaveItemSuccess } from "~/constants/alertMsg";

@Component
export default class ForgotPasswordModal extends BaseForm<any> {
  @Provide("form_name") form_name = "forgot_password_form";

  show = false;
  showSuccessModal = false;

  created() {}

  openModal() {
    this.show = true;
  }

  closeModal() {
    this.show = false;
  }

  openSuccessModal() {
    this.showSuccessModal = true;
  }
  closeSuccessModal() {
    this.showSuccessModal = false;
  }

  emitForgotSubmit(data: any) {
    this.forgotPasswordAttributeLoader.$add(data);
  }

  get forgotPasswordAttributeLoader() {
    return new ObjectLoader(
      this,
      (data?: any): ILoaderOption => ({
        // baseURL: Core.config.AUTHENTICATION_PATH == 'ecom' ? Core.ECOMCMSAPI(`/admin/confirm-access-token/${accessToken}`) : Core.MKTBACKENDAPI(`/admin/confirm-access-token/${accessToken}`),
        apiType: "ECOMCMS",
        baseURL: Core.MKTBACKENDAPI("/forgot-password"),
        baseAdd: Core.MKTBACKENDAPI("/forgot-password"),

        onAddSuccess: (newValue) => {
          let res = this.forgotPasswordAttributeLoader.addItem;
          if (res.success) {
            this.closeModal();
            this.openSuccessModal();
          }
          // this.$swal(SaveItemSuccess as SweetAlertOptions)
          this.$emit("close");
        },

        onAddError: (newValue) => {
          this.$swal(
            UnexpectedError(newValue?.errorData?.message) as SweetAlertOptions
          );
        },
      })
    );
  }

  @NoCache
  get formFields(): IFormOption[] {
    return [
      {
        type: INPUT_TYPES.TEXT,
        col: "12",
        props: {
          rules: [RuleHelper.required],
          name: "email",
          label: "Email",
          defaultValue: "",
        },
      },
    ];
  }
}
