
import {Component} from 'vue-property-decorator'
import {BaseApp} from '~/core/Base'
import { Core } from "~/core/Core";

@Component
export default class Logo extends BaseApp {
  
  changeThemeLogo() {
    if (Core.config.MARKETING_THEME == "BZB") {
      return "/images/logo-bzb-profile.png"
    }

    return "/images/logo-profile.png"
  }
}
