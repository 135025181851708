import { render, staticRenderFns } from "./login.vue?vue&type=template&id=b4479d50&"
import script from "./login.vue?vue&type=script&lang=ts&"
export * from "./login.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/app/components/Logo.vue').default})
