
import { Component, Provide } from "vue-property-decorator";
import Logo from "~/components/Logo.vue";
import { ILoaderOption } from "~/loader/types";
import { Core } from "~/core/Core";
import { ObjectLoader } from "~/loader/object";
import { IFormOption, INPUT_TYPES } from "~/components/form/FormTypes";
import { BaseForm } from "~/core/BaseForm";
import RuleHelper from "~/utils/RuleHelper";
import { CookieHelper } from "~/utils/CookieHelper";
import { LocalStorageHelper } from "~/utils/LocalStorageHelper";
import { UnexpectedError } from "~/constants/alertMsg";
import {API_TYPE} from '~/constants/apiTypes';
import SweetAlertOptions from "vue-sweetalert2";
import Msal from "~/plugins/msal";
import ForgotPasswordModal from "~/features/user/ForgotPasswordModal.vue";
import AppLoading from '~/layouts/components/AppLoading.vue';
import ADToken from "~/plugins/ad-token";

@Component({
  layout: "none",
  components: {
    Logo,
    ForgotPasswordModal,
    AppLoading,
  },
})
export default class Index extends BaseForm<any> {
  @Provide("form_name") form_name = "login_form";
  

  isLoading: boolean = true;

  async created() {
    // await ADToken.loginADToken(this)
    this.loginLoader.initial();
    this.authLoader.initial();
  }

  // created() {
  //   this.loginLoader.initial();
  //   this.authLoader.initial();
  // }

  async mounted() {
    this.$app.switchToDefault();

    const me = LocalStorageHelper.get('me')
    if (me?.token) {
      this.$app.saveToState('me', me)
      await this.authLoader.$get('')
    } else {
      // this.$auth.logout(this);
    }

    await new Promise(resolve => setTimeout(resolve, 400))
    this.isLoading = false;
  }

  get loginFormFields(): IFormOption[] {
    return [
      {
        type: INPUT_TYPES.TEXT,
        col: "12",
        props: {
          rules: [RuleHelper.required, RuleHelper.email],
          name: "email",
          label: "Email",
        },
      },
      {
        type: INPUT_TYPES.PASSWORD,
        col: "12",
        props: {
          rules: [RuleHelper.required],
          name: "password",
          label: "Password",
        },
      },
    ];
  }

  get loginLoader() {
    return new ObjectLoader(
      this,
      (data?: any): ILoaderOption => ({
        baseURL:
          Core.config.AUTHENTICATION_PATH == "ecom"
            ? Core.ECOMCMSAPI("/login")
            : Core.MKTBACKENDAPI("/login"),
        stateKey: "me",

        onAddSuccess: (newValue) => {
          this.$app.replaceState("me", this.loginLoader.addItem);
          LocalStorageHelper.set("me", this.loginLoader.addItem);
          let tokenData = {
            token: this.loginLoader.addItem.token || null,
          };
          let cookie = JSON.stringify(tokenData);
          CookieHelper.set("me", cookie);

          const backURL: string = this.$route.query?.backURL as string ?? "";
          const token = JSON.parse(CookieHelper.get("me") || "{}")?.["token"];

          if(token) {
            if (backURL) {
              const urlObj = new URL(backURL);
              urlObj.searchParams.set("token", token);
              console.log("back to =>", urlObj.toString());
              window.location.href = urlObj.toString();
            }
            else {
              this.$router.push({
                path: this.$route.query.redirect ? this.$route.query.redirect : "/",
              });
            }
          }
        },

        onAddError: (newValue) => {
          this.$swal(
            UnexpectedError(newValue.errorData?.message) as SweetAlertOptions
          );
        },
      })
    );
  }

  get authLoader(): ObjectLoader<{ permissions: string[], token: string }> {
    return new ObjectLoader(this, (data?: any): ILoaderOption => ({
      apiType: API_TYPE.ECOM,
      baseURL: Core.MKTBACKENDAPI('/me'),
      baseFind: Core.MKTBACKENDAPI('/me'),
      stateKey: 'me',

      onFindSuccess: (newValue) => {
        const permissions: object = {}
        for (let i = 0; i < this.authLoader.findItem.permissions.length; i++) {
          permissions[this.authLoader.findItem.permissions[i]] = true
        }
        this.$app.saveToState('me', {
          permissions: permissions
        })
        LocalStorageHelper.set('me', {
          ...LocalStorageHelper.get('me'),
          ...this.authLoader.findItem
        })
        const backURL: string = this.$route.query?.backURL as string ?? "";
        const token = JSON.parse(CookieHelper.get("me") || "{}")?.["token"];

        if(token) {
          if (backURL) {
            const urlObj = new URL(backURL);
            urlObj.searchParams.set("token", token);
            window.location.href = urlObj.toString();
          }
          else {
            this.$router.push({
              path: this.$route.query.redirect ? this.$route.query.redirect : "/",
              // path: '/',
            })
          }
        }

      },
      onFindError: newValue => {

      },
    }))
  }

  emitSubmit(data: any) {
    this.loginLoader.$add(data);
  }

  destroyed() {
    this.loginLoader.destroyLoader();
  }

  loginAzureAD() {
    Msal.signIn().then((res: any) => {
      if (Object.keys(res).length > 0) {
        if (res?.account !== undefined && res?.accessToken !== undefined) {
          if (res?.account.username !== undefined) {
            let data = {
              email: res.account.username,
              azure_token: res.accessToken,
            };
            this.loginAzureADLoader.$add(data);
          }
        }
      }
    });
  }

  logoutAzureAD() {
    Msal.signOut();
  }

  get loginAzureADLoader() {
    return new ObjectLoader(
      this,
      (data?: any): ILoaderOption => ({
        baseURL:
          Core.config.AUTHENTICATION_PATH == "ecom"
            ? Core.ECOMCMSAPI("/login/azure-ad")
            : Core.MKTBACKENDAPI("/login/azure-ad"),
        stateKey: "me",

        onAddSuccess: (newValue) => {
          this.$app.replaceState("me", this.loginAzureADLoader.addItem);
          LocalStorageHelper.set("me", this.loginAzureADLoader.addItem);
          // CookieHelper.set("me", {
          //   token: this.loginAzureADLoader.addItem.token || null,
          // });
          let tokenData = {
            token: this.loginAzureADLoader.addItem.token || null,
          };
          let cookie = JSON.stringify(tokenData);
          CookieHelper.set("me", cookie, { expires: 30 });

          this.$router.push({
            path: this.$route.query.redirect ? this.$route.query.redirect : "/",
          });
        },

        onAddError: (newValue) => {
          this.$swal(
            UnexpectedError(newValue.errorData?.message) as SweetAlertOptions
          );
        },
      })
    );
  }

  get enabledFeatureLoginAzureAD() {
    let isEnableFeature = Core.config.ENABLED_FEATURE_LOGIN_AZURE_AD;
    return isEnableFeature;
  }

  get enabledFeatureLogin() {
    let isEnableFeature = Core.config.ENABLED_FEATURE_LOGIN;
    return isEnableFeature;
  }

  get enabledFeatureForgotPassword() {
    let isEnableFeature = Core.config.ENABLED_FEATURE_FORGOT_PASSWORD;
    return isEnableFeature;
  }
}
